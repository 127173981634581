.container {
    position: relative;
    margin-bottom: 0.5rem;
    border-radius: 0.7rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border: 1px solid black;
    background-color: white;
  }
  
  .label {
    display: inline-block;
    margin-left: 0.5rem;
    color: black;
    font-size: 1rem;
  }
  
  .content {
    padding: 0 0.5rem;
    height: 2.7rem;
    display: flex;
    align-items: center;
  }