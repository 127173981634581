.header {
    background: #141414;
    padding: 0;
    border-bottom: 2px solid rebeccapurple;
}
.h1 {
    color: #a600ff;
    font-size: 15px;
    padding-right: 1rem
}

.header a {
    color: white;
    font-weight: 600;
}

.header a:hover {
    background: rebeccapurple;
    color: white;
    cursor: pointer;
}

.container {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.logo {
    font-weight: bold;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.header ul {
    display: flex;
    list-style-type: none;
    margin: 0;
}

.header ul a {
    padding: 1rem;
    padding-right: 4rem;
    display: inline-block;
}

.menu_container {
    position: relative;
}

.menu {
    position: absolute;
    z-index: 1;
    background-color: #363636;
    display: none;
}

.menu_container:hover .menu {
    display: block;
}

.menu a {
    width: 100%;
    min-width: 8rem;
}

.coin_count {
    background-color: rebeccapurple;
    color: white;
    padding: 0.1rem 0.35rem;
    border-radius: 100rem;
    font-size: 0.9rem;
}

.logo img {
    width: 280px;
    height: 80px;
}

.coinLogo img {
    width: 30px;
    height: 30px;
}

.loginButton {
    margin-right: 10px;
}

.promo {
    color: white;
    font-size: 15px;
}

.sponsoredOffersHeading {
    margin-bottom: 0.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
    font-size: 25px;
    color: white;
}