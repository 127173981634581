.list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.list li a {
    height: 10.5rem;
    width: 14rem;
    border: 1px solid rebeccapurple;
    border-radius: 1rem;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    color: rebeccapurple;
    background-color: white;
    box-shadow: 1px 2px 9px;
}

.image {
    object-fit: contain;
    height: 12.5rem;
    border-radius: 1rem 1rem 0 0;
}

.notikimage {
    object-fit: contain;
    height: 12.5rem;
    padding-top: 25px;
    border-radius: 1rem 1rem 0 0;
}


.content {
    margin-top: 0.3rem;
    padding: 0.5rem 1rem;
    position: relative;
    height: 7rem;
}

.popular {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    font-size: 1rem;
}

.favorite.not {
    color: gray;
}

.stars {
    margin: 0.5rem 0.5;
}

.product_item_footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.origins span {
    border-radius: 2rem;
    background-color: whitesmoke;
    display: inline-block;
    font-size: 0.8rem;
    color: gray;
    margin-right: 0.3rem;
    padding: 0 0.5rem;
    margin-top: 0.2rem;
}
