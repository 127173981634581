.footerWrapper {
    background: #141414;
    margin-top: 10rem;
    border-top: 2px solid rebeccapurple;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2.5rem;
    padding-top: 1rem;
}

.contentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 1rem; /* Reduced padding */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    gap: 1rem; /* Add gap between wrapped items */
}

.footerSectionOne {
    margin-right: 0; /* Remove margin */
    width: 100%; /* Full width on mobile */
    max-width: 200px; /* Limit logo container width */
}

.footerLogoContainer {
    text-align: center;
}

.footerLogoContainer img {
    max-width: 100%; /* Adjust logo size */
    height: auto;
}

.footerSectionTwo,
.footerSectionThree {
    margin-right: 0; /* Remove margin */
    flex: 1; /* Allow sections to grow */
    min-width: 150px; /* Minimum width before wrapping */
}

.footerSectionColumns {
    display: flex;
    flex-direction: column;
}

.footerText {
    color: white;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
}

/* Add media query for mobile screens */
@media (max-width: 768px) {
    .contentWrapper {
        flex-direction: column;
        text-align: center;
        gap: 2rem;
    }

    .footerSectionOne,
    .footerSectionTwo,
    .footerSectionThree {
        width: 100%;
    }

    .footerSectionColumns {
        align-items: center;
    }

    .footerText {
        padding-right: 0;
    }
}