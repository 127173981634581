

.item {
    width: 33%;
    padding: 5px;
}

.item .card {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
}

.innerCarousel {
    display: flex;
    flex-direction: row;
}

.carousel {
    cursor: grab;
    overflow: hidden;
    padding-right: 80px;
}