.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 84px);
    padding: 2rem;
    background-color: white;
}

.formContainer {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: white;
    border: 2px solid rebeccapurple;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    color: black;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    color: black;
    font-weight: 600;
    font-size: 0.9rem;
}

.input {
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.input:focus {
    outline: none;
    border-color: rebeccapurple;
}

.button {
    background-color: rebeccapurple;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
}

.button:hover {
    background-color: #553098;
}

.link {
    color: rebeccapurple;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.2s;
}

.link:hover {
    color: #553098;
}

.linkText {
    text-align: center;
    margin-top: 1rem;
    color: black;
}
