.input {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 0 solid grey;
    transition: border-width 0.15s ease-out;
    background-color: white;
    font-size: 1.1rem;
    outline: none;
  }
  
  .input::placeholder {
    color: #dfdfdf;
    font-size: 0.95rem;
  }
  
  .input:focus {
    border-width: 2.9px;
  }
  
  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1rem;
    height: 100%;
    width: 12rem;
    color: red;
    text-align: center;
    font-size: 0.95rem;
  }