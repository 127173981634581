.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 3rem;
  }
  
  .details {
    width: 25rem;
  }
  
  .details form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .agree {
    color: white;
    margin: 1rem;
  }
  
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white
  }

  .checkbox {
    transform: scale(1.5);
    margin-right: 12px;
  }

  .termLink {
    color: black;
  }

  .error {
    color: red;
  }

