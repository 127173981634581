:root {
    background-color: #242424;
  }
  
  .card {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 32%;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 1px 2px 9px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Default to row direction */
    flex-direction: row;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .gameLogo {
    position: relative !important;
    z-index: 10;
  }
  
  .gameLogo img {
    border-radius: 10px;
  }
  
  .android img {
    position: absolute;
    top: 27px;
    z-index: 10;
    left: 92px;
  }
  
  .second {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .level {
    font-size: 15px;
  }
  .heading {
    margin: 0;
  }
  h1 {
    margin: 0;
    font-size: 20px;
    color:rgb(89, 55, 240)
  }
  p {
    margin: 0;
  }
  button {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    height: 50px; /* Set a fixed height */
    
  }

  .link {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: black;
    color: white;
    font-size: 20px;
    padding: 35px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    height: 50px; /* Set a fixed height */
    overflow: hidden; /* Prevent overflow */
  }
  
  .third {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: black;
    color: white;
    font-size: 15px;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    height: 50px; /* Set a fixed height */
    cursor: pointer;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  body.modal-open {
    overflow: hidden;
  }

  .device-icon {
    position: relative;
    margin-top: 5px;
  }

  @media (min-width: 768px) {
    .card {
      flex-direction: row;
      width: 70%;
    }
    
    .gameLogo img {
      width: 100px;
    }
  }
  
  @media (min-width: 1024px) {
    .card {
      flex-direction: column;
      width: 50%;
    }
    
    .gameLogo img {
      width: 100px;
    }
  }
  
  /* Add styles for mobile/narrow screens */
  @media (max-width: 767px) {
    .card {
      flex-direction: row;
      width: 95%;
      padding: 8px;
      min-height: 80px;
    }
    
    .gameLogo img {
      width: 60px;
      height: 60px;
    }
    
    .second {
      flex: 1;
      padding: 0 10px;
    }
    
    .heading h1 {
      font-size: 16px;
    }
    
    .level p {
      font-size: 13px;
    }
    
    .third {
      height: auto;
      padding: 8px 12px;
    }
    
    .third p {
      font-size: 14px;
    }
    
    .device-icon img {
      width: 20px;
    }
  }