.background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
}

.container {
    width: 500px;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    max-width: 80%;
}

.title {
    text-align: center;
}

.body {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    text-align: center;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.footer button {
    width: 150px;
    height: 45px;
    border: none;
    background-color: #a600ff;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}