* {
  box-sizing: border-box;
}

html {
  font-size: 18px;
}

body {
  margin: 0;
  font-family: sans-serif;
  background-color: white;
}

.with-background-image {
  background-image: url('../public/cintaku_8.jpg');
  background-size: cover;
  background-color: #818589;
}

a {
  text-decoration: none;
}