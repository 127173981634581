.container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    min-height: calc(100vh - 84px);
}

.tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #e2e8f0;
}

.tab {
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    color: #64748b;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
}

.tab.active {
    color: rebeccapurple;
    border-bottom: 2px solid rebeccapurple;
}

.welcome {
    color: black;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid rebeccapurple;
}

.statsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.statCard {
    background-color: white;
    border: 2px solid rebeccapurple;
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.2s;
}

.statCard:hover {
    transform: translateY(-2px);
}

.statValue {
    font-size: 2rem;
    font-weight: bold;
    color: rebeccapurple;
    margin-bottom: 0.5rem;
}

.statLabel {
    color: black;
    font-weight: 600;
    font-size: 1rem;
}

/* Placements Styles */
.placementsContainer {
    padding: 1rem 0;
}

.tableContainer {
    margin-bottom: 2rem;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
}

.table th,
.table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
}

.table th {
    background-color: #f8fafc;
    font-weight: 600;
    color: #1e293b;
}

.table tr:hover {
    background-color: #f8fafc;
}

.addButton {
    background-color: rebeccapurple;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
}

.addButton:hover {
    background-color: #553098;
}

/* Modal Styles */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal h2 {
    margin-bottom: 1.5rem;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
}

.modal .inputGroup {
    margin-bottom: 1rem;
}

.modal label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: black;
}

.modal input,
.modal select {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 4px;
    font-size: 1rem;
}

.modal input:focus,
.modal select:focus {
    outline: none;
    border-color: rebeccapurple;
}

.modalButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.modalButtons button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
}

.modalButtons button[type="button"] {
    background-color: #e2e8f0;
    border: none;
    color: #64748b;
}

.modalButtons button[type="submit"] {
    background-color: rebeccapurple;
    border: none;
    color: white;
}

.modalButtons button:hover {
    transform: translateY(-1px);
}

.gearButton {
    background-color: rebeccapurple;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.gearButton:hover {
    background-color: #553098;
    transform: scale(1.05);
}

.formGroup {
    margin-bottom: 1rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.formGroup input,
.formGroup select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.formGroup small {
    display: block;
    margin-top: 0.25rem;
    color: #666;
    font-size: 0.85rem;
    line-height: 1.4;
}

.modalContent h3 {
    margin: 1.5rem 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
    color: rebeccapurple;
}

.copyContainer {
    display: flex;
    gap: 8px;
    margin-bottom: 4px;
}

.copyContainer input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: monospace;
    font-size: 0.9rem;
    color: #666;
    cursor: text;
}

.copyButton {
    padding: 8px 16px;
    background-color: rebeccapurple;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.copyButton:hover {
    background-color: #663399;
}
