.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: 10px;
  margin: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .list {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding: 20px;
  }
}

.item {
  display: flex;
  height: 100%;
  padding: 0.25rem;
}

.offerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

/* Make sure WallOffer components take up full height */
.offerWrapper > * {
  flex: 1;
  height: 100%;
}

@media (max-width: 767px) {
  .item {
    padding: 0.15rem;
  }
}