.subsection {
    margin-top: 10px;
}

.section {
    margin-top: 30px;
}

.heading {
    margin-top: 30px;
}

.main {
    margin-left: 50px;
    margin-right: 50px;
    color: whitesmoke;
}