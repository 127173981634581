.sponsoredOffersHeading {
    margin-bottom: 0.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
    font-size: 30px;
    color: white;
}

.image {
    object-fit: contain;
    height: 12.5rem;
}

.content {
    margin-top: 0.3rem;
    padding: 0.5rem 1rem;
    position: relative;
    height: 7rem;
}

.popular {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    font-size: 1.3rem;
}

.favorite.not {
    color: gray;
}

.stars {
    margin: 0.5rem 0;
}

.product_item_footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.origins span {
    border-radius: 2rem;
    background-color: whitesmoke;
    display: inline-block;
    font-size: 0.8rem;
    color: gray;
    margin-right: 0.3rem;
    padding: 0 0.5rem;
    margin-top: 0.2rem;
}

.line {
    border: 1px solid #ddd; /* Light gray border */
    width: 80%; /* Adjust width as needed */
    margin-left: 2rem;
    margin-bottom: 0.5rem;
}

.categories {
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 50%; /* Ensure the container takes the full width */
padding: 0 1rem; /* Add padding to the container */
box-sizing: border-box;
margin-top: 1rem;
}

.categories button {
  margin: 0 0.5rem; /* Add margin to the buttons */
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
    padding: 20px;
    list-style-type: none;
  }

  @media (min-width: 768px) {
    .list {
      grid-template-columns: 1fr 1fr;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sponsoredOffersHeading {
    margin-bottom: 0.5rem;
    padding-top: 2rem;
    padding-left: 2rem;
    font-size: 30px;
    color: white;
}