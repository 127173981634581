.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

.content {
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #333;
}

.linkContainer {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
}

.linkInput {
    flex: 1;
    padding: 0.75rem;
    border: 2px solid #e0e0e0;
    border-radius: 0.5rem;
    font-size: 1rem;
    background: #f5f5f5;
}

.infoBox {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
}

.infoBox h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #333;
}

.infoBox ol {
    margin: 0;
    padding-left: 1.5rem;
}

.infoBox li {
    margin-bottom: 0.5rem;
    color: #555;
}

@media (max-width: 768px) {
    .linkContainer {
        flex-direction: column;
    }
    
    .linkInput {
        width: 100%;
    }
}
