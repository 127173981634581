.heading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headingContainer {
    margin-top: 90px;
    margin-bottom: 0;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
  
  .contactForm {
    width: 600px; /* Adjust the width as needed */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: black;
  }
  
  .contactForm label {
    color: white;
    display: block;
    margin-bottom: 8px;
  }
  
  .contactForm input[type="text"],
  .contactForm input[type="email"],
  .contactForm textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .contactForm textarea {
    height: 120px;
  }
  
  .contactForm input[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: rebeccapurple;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contactForm input[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .contactForm p {
    margin-top: 8px;
    color: #007bff;
  }