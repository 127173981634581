.rewardProgressContainer {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: Arial, sans-serif;
  }

  .rewardHeader {
    background-color: #6200ea;
    color: white;
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
  }

  .rewardContent {
    padding: 16px;
  }

  .eventList {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .eventItem {
    display: flex;
    align-items: flex-start;
  }

  .eventIconContainer {
    position: relative;
    margin-right: 16px;
  }

  .eventIcon {
    width: 32px;
    height: 32px;
    background-color: #e8f5e9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4caf50;
    color: #4caf50;
  }

  .eventLine {
    position: absolute;
    top: 32px;
    left: 50%;
    width: 2px;
    height: calc(100% + 16px);
    background-color: #e0e0e0;
    transform: translateX(-50%);
  }

  .eventDetails {
    flex-grow: 1;
  }

  .eventDescription {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .eventValue {
    color: #4caf50;
    border: 1px solid #4caf50;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 14px;
  }

  .eventText {
    color: #333;
    font-weight: 500;
  }

  .eventSubDescription {
    font-size: 14px;
    color: #757575;
    margin-top: 4px;
  }

  .rewardButton {
    display: block;
    width: 100%;
    background-color: #4caf50;
    color: white;
    text-align: center;
    padding: 12px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    margin-top: 24px;
    transition: background-color 0.3s;
  }

  .rewardButton:hover {
    background-color: #45a049;
    cursor: pointer;
  }

  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: black;
    background: none;
    border: none;
    cursor: pointer;
  }