.btn {
  background: linear-gradient(to right, rebeccapurple, #a600ff);
  color: white;
  border: none;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
}

.btn:hover {
    background-color: #0055cc;
  }
  
.btn span {
    margin-left: 10px;
}