.sponsoredOffersHeading {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    font-size: 30px;
    color: white;
    text-align: center;
}

.info {
    max-width: 35rem;
    padding-bottom: 1rem;
    margin: 0 auto;
    text-align: center;
    color: whitesmoke;
}